<template>
    <div>
        <div class="container d-flex align-items-center vh-100 justify-content-center">
            <div>
                <div class="text-center">
                    <div class="logo-wrapper">
                        <img src="@/assets/img/bau-logo-for-light.svg"/>
                    </div>
                </div>
                <div class="text-center my-5">
                    <span>
                        <i class="ri-settings-5-line ri-8x m-0 p-0"></i>
                    </span>
                    <h4 class="mb-1">{{ $t('system_maintance') }}</h4>
                    <p style="font-size: 16px">
                        <a target='_blank' href='https://shiftdelete.net/dns-ayarlari-nasil-degistirilir'>Windows</a><br><a target='_blank' href='https://support.apple.com/tr-tr/guide/mac-help/mh14127/mac'>Mac</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Error503',
        methods: {
            goBack() {
                this.$router.push('/')
            }
        }
    }
</script>
